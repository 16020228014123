import { ValidacionService } from "../services/LandingV4/validacion";

const getTenant = async (creditApplicationId) => {
    try {        
        const validation = new ValidacionService(creditApplicationId);
        const res = await validation.send()
        const { tenantId } = res.data
        return tenantId
    } catch (error) {
        console.log(error)
    }
}

export default getTenant